/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import { useContext, useState } from "react";
import { Formik, Form } from "formik";

import Client from "../../client";
import { useMutation } from "../../hooks";
import AuthContext from "../../context/auth-context";

import { FormElement } from "../../components/form-element/form-element";
import Button from "../../components/button/button";
import { LoginLogo } from "../../components/icons/logo-login";

import { css } from "./css";

const initialValues = {
  email: "",
  password: "",
};

const validate = () => ({});

const Login = () => {
  // const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [hasError, setHasError] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  const { mutateAsync: onSubmit, isLoading, error } = useMutation({
    mutationKey: "login_user",
    mutationFn: async (data) => Client.post("/auth/login", data),
    onSuccess: (data) => {
      login(data);
    },
    onError: () => {
      setHasError(true);
    },
    isProtected: false
  });

  // const handleSignup = () => {
  //   navigate("/signup");
  // };

  const handlePasswordReset = async () => {
    // Perform password reset function here
    console.log("handle reset functionality");
    try {
      let email = document.getElementById("email").value;

      await Client.post("/auth/forgot-password", { email });
      alert("Check your email for a link to reset your password!");
    } catch (err) {
      console.log(err);
      alert(
        "Error occured trying to send password reset email, the email was not sent",
      );
    }
  };

  const theme = useTheme();

  function handleErrorBtn() {
    setTimeout(() => setHasError(false), 500);
  }

  const errorBtn = (
    <Button
      label="Try Again"
      color="danger"
      onClick={handleErrorBtn}
      theme={theme}
      css={css.errorBox(theme).btn}
    />
  );

  const errorBox = (
    <div css={css.errorBox(theme)}>

      <div css={css.errorBox().msg}>
        <h2>Error!</h2>
        <p>Wrong Email or Password</p>
      </div>

      {errorBtn}
    </div>
  );

  const ressetPassword = isResettingPassword && (
    <div css={[css?.formBlock, css?.formBlock?.reset]}>
      <div css={css?.formBlock?.intro}>
        <h1>Reset your password</h1>
        <p>If you have forgotten your password you can reset it here.</p>
      </div>

      <div css={css?.formBlock?.elements}>
        <FormElement
          element="input"
          elementType="text"
          id="email"
          name="email"
          label="Email"
        />

        <Button label="Send My Password" onClick={handlePasswordReset} />
    
        <p css={css.account(theme)}>
          <span onClick={() => setIsResettingPassword(false)}>Back to Log in</span>
        </p>
      </div>
    </div>
  );

  const header = <LoginLogo />;
  const form = (formik) => (
    <div css={css?.formBlock} style={{ opacity: isResettingPassword ? 0 : null }}>
      <div css={css?.formBlock?.intro(theme)}>
        <h1>Log in to your account</h1>
        <p>Welcome back! Please enter your details.</p>
      </div>

      <div css={css?.formBlock?.elements}>
        <FormElement
          element="input"
          elementType="text"
          id="email"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
          label="Email"
        />

        <FormElement
          element="input"
          elementType="password"
          id="password"
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.password}
          label="Password"
        />
      </div>

      <div css={css.formBlock.forgotlink(theme)}>
        <span onClick={() => setIsResettingPassword(true)}>Forgot password?</span>
        {/* <span onClick={() => handleSignup()}>Register</span> */}
      </div>
      <Button
        label="Sign in"
        onClick={formik.handleSubmit}
        loading={isLoading}
        disabled={isLoading || Object.values(formik.errors).length || hasError}
        type="submit"
      />
      {/* <div css={css.signup(theme)}>
        Don't have an account? <span onClick={() => setIsResettingPassword(true)}>Sign Up</span>
      </div> */}
    </div>
  );

  return (
    <div css={css.container(theme)}>
      <div css={css.block}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
        >
          {(formik) => (
            <Form>
              {hasError && errorBox}
              {header}
              {ressetPassword}
              {form(formik)}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;