import React from "react";
import { Route } from "react-router-dom";

import ProtectedLayout from "../layouts/protected";

import Main from "../components/main/main";
import Stalls from "../pages/stalls/stalls";
import StallHorses from "../pages/stall-horses/stall-horses";
import Horses from "../pages/horses/horses";
import Users from "../pages/users/users";
import ViewHorse from "../pages/view-horse/view-horse";
import Onboarding from "../pages/onboarding/onboarding";
import ViewUser from "../pages/view-user/view-user";

const AdminRouter = (
  <Route element={<ProtectedLayout />}>
    <Route path="/" element={<Main/>}>
      <Route path="stalls" element={<Stalls />}></Route>
      <Route path="stall/:id" element={<StallHorses />}></Route>
      <Route path="horses" element={<Horses />}></Route>
      <Route path="horse/:id" element={<ViewHorse />}></Route>
      <Route path="users" element={<Users />}></Route>
      <Route path="user/:id" element={<ViewUser />}></Route>
      <Route path="onboarding" element={<Onboarding />}></Route>
    </Route>
  </Route>
);

export default AdminRouter;