/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import { GrFormUpload } from "react-icons/gr";
import Button from "react-bootstrap/Button";

import {
  borderRadius,
  flexbox,
  fontbox,
  fullHeight,
  fullWidth,
  padding,
  posRelative,
  square,
  theme,
} from "../../emotion/utils";

export default function DragAndDrop({
  file,
  placeholder,
  onClick,
  onChange,
  className,
}) {
  // Create a URL for the uploaded file
  const fileUrl = useMemo(() => {
    if (!file) return placeholder;

    if (file instanceof File) {
      return URL.createObjectURL(file);
    }

    return file;
  }, [file, placeholder]);

  const dropHandler = (e) => {
    e.preventDefault();

    if (!e.dataTransfer.items) {
      return;
    }

    Array.from(e.dataTransfer.items).forEach((item, i) => {
      if (item.kind !== "file" || !onChange) {
        return;
      }

      const file = item.getAsFile();
      onChange(file);
    });
  };

  // prevent browser's default drag behavior
  const dragOverHandler = (ev) => {
    ev.preventDefault();
  };

  // const link = formik.values.avatar?.link;
  // const link =  formik.values.avatar?.link ||
  //               formik.values.icon?.link ||
  //               formik.values.map?.link ||
  //               formik.values.eventMap?.link;

  const logo = (
    <div css={css.container}>
      <div
        css={css.dropzone}
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
        onClick={onClick}
      >
        <GrFormUpload color={theme.colors.darkBlue} />
        <div css={css.direct}>
          <div css={css.direct.title}>{placeholder}</div>
          <div css={css.direct.sub}>or click to browse</div>
        </div>
      </div>
    </div>
  );

  if (!file) {
    return logo;
  }

  return (
    <div css={[css.container(fileUrl), css.hasImg]}>
      <div
        css={css.dropzone}
        onDrop={dropHandler}
        // onDragOver={dragOverHandler}
        onChange={onChange}
        onClick={onClick}
      >
        <div>
          <Button
            variant="outline-light"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Edit Image
          </Button>
        </div>
      </div>
    </div>
  );
}

const css = {
  container: (fileUrl) => ({
    ...borderRadius(4),
    ...fullWidth(),
    backgroundColor: theme.colors.white,
    backgroundImage: `url('${fileUrl}')`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    cursor: "pointer",
    overflow: "hidden",
  }),

  dropzone: {
    ...borderRadius(4),
    ...flexbox("row", "center", "center", 20),
    ...fullHeight(),
    ...padding(13, 13, 13, 13),
    ...posRelative(null, null, null, null, 1),
    backgroundColor: theme.colors.white,
    border: `1px dashed ${theme.colors.lightGrey}`,

    svg: {
      ...square(40),
      color: `${theme.colors.darkBlue}!important`,
    },
  },

  direct: {
    ...flexbox("column", "center", "center", 5),
    color: theme.colors.darkBlue,

    title: {
      ...fontbox(theme.fonts.inter, 16, 500, 16),
      color: theme.colors.darkBlue,
    },

    sub: {
      ...fontbox(theme.fonts.inter, 12, 400, 12),
      color: theme.colors.darkBlue,
    },
  },

  hasImg: {
    height: 150,

    "> div": {
      backgroundColor: theme.colors.transparent,
    },
  },
};
