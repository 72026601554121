import {
  appColors,
  appFonts,
  flexbox,
  fontbox,
  fullWidth,
  posRelative,
  square,
} from '../../emotion/utils'

export const css = {
  cameraArea: {
    ...posRelative(),
    ...flexbox('row', 'flex-start', 'flex-start', 8, 'wrap'),
    ...fullWidth(),
    // paddingRight: 36,

    // "> div": {
    //   // ...posAbsolute(0, 0, null, null),
    // },
  },

  formEl: {
    ...flexbox('column', 'flex-start', 'flex-start', 4),
    ...fullWidth(),

    label: {
      ...fontbox(appFonts?.inter, 14, 700, 14),
      color: appColors?.grays?.[900],
    },

    '> div': {
      ...fullWidth(),
    },
  },

  settings: {
    ...flexbox('column', 'center', 'center'),
    ...square(36),
    cursor: 'pointer',
  },
}
