import {
  borderRadius,
	flexbox,
	fullWidth,
	objectContain,
	posRelative,
	square,
	theme,
} from "../../emotion/utils";

// const getHeight = (isSelected, loading) => {
//   // console.log('getHeight isSelected', isSelected);
//   // console.log('getHeight loading', loading);
//   // let height = 335;

//   // console.log('getHeight height', height);

//   const height = !isSelected
//     ? 215
//     : isSelected && loading
//     ? 550
//     : "unset";

//   return height;
// };

export const css = {
	container: (loading) => ({
		...fullWidth(),
    height: loading ? 215 : 'auto',

		"> div": {
      ...borderRadius(12),
			...flexbox("column", "center", "center", 0),
      ...posRelative(),
      ...square("inherit"),
			backgroundColor: loading ? theme.colors.white : `${theme.colors.textPrimary}25`,
			overflow: "hidden",

			canvas: {
        ...objectContain()
			}
		}
	}),

  loader: {
    ...flexbox("column", "center", "center", 0),

    img: {
      ...square(`120px!important`)
    }
  }
};
