/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { Dropdown } from 'primereact/dropdown';
import { useQuery } from "../../hooks";
import { ENDPOINT } from "../../client";
import { appColors } from "../../emotion/utils";

import NoteListingComponent from "../../components/note-listing-component/note-listing-component";
import VideoComponent from "../../components/video-component/video-component";
import CameraSelectorComponent from "../../components/camera-selector-component/camera-selector-component";
import NoteEntryComponent from "../../components/note-entry-component/note-entry-component";
import RequestVetButton from "../../components/request-vet-button/request-vet-button";
import Icon from "../../components/icon/icon";

import { css } from "./css";

export default function StallTray(props) {
  const {
    activeStall,
    setActiveStall,
    refetchStall,
  } = props;

  const [activeCamera, setActiveCamera] = useState(null);
  const [activeHorse, setActiveHorse] = useState(null);
  const [horseList, setHorseList] = useState(null);
  const [notesUri, setNotesUri] = useState("/notes");
  const [fullScreen, setFullScreen] = useState(false)

  useEffect(() => {
    console.log('check active horse..', activeHorse);
    if (activeHorse) {
      const horseId = activeHorse.id;
      const url = new URL("/notes", ENDPOINT);
      url.searchParams.append(
        "horse_id",
        horseId
      );
      console.log("url is..", url);
      const compiledUrl = url.toString().replace(ENDPOINT, "");
      console.log('compiled url..', compiledUrl);
      setNotesUri(compiledUrl);
    }
  }, [activeHorse]);
  
  const { data: notesData, refetch } = useQuery(notesUri);

  useEffect(() => {
    if(activeStall?.default_camera) {
      setActiveCamera(activeStall?.default_camera)
    } else if(activeStall?.cameras?.length > 0) 
      setActiveCamera(activeStall?.cameras[0])
    const opts = activeStall?.horses_assigned?.map((horse) => {
      return {
        label: horse.horse.name,
        value: horse.horse.id
      }
    })
    setHorseList(opts)
    if(opts)setActiveHorse(activeStall?.horses_assigned[0].horse)

  }, [activeStall])

  const onFetchMoreNotes = () => {
    console.log('on fetch more notes fired.');
    //getNotes(activeHorse?.id);
    refetch();
  };

  const getNotes = (horseId) => {
    let filteredNotes = [];

    notesData?.data?.map((row) => {
       if (row?.horseId === horseId) {
         filteredNotes.push({
           ...row,
           name: row.name,
           id: row.id,
         });
       }    
    });

    if (filteredNotes.length === 0) {
      return <p>No notes found.</p>
    }

    return <NoteListingComponent css={css.stallNotes} notes={filteredNotes} />;
  };

  const videoBlock = (
    <div css={css.videoBlock}>
      {/* {currentCamera && <VideoComponent currentCamera={currentCamera} />} */}
      <VideoComponent currentCamera={activeCamera} full_screen={fullScreen} />
      
      <CameraSelectorComponent
        activeCamera={activeCamera}
        setActiveCamera={setActiveCamera}
        activeStall={activeStall}
        refetch={refetchStall}
      />

      <Dropdown
        onChange={(it) => {setActiveHorse(activeStall?.horses_assigned?.find((i) => i.horse.id === it?.value).horse)}}
        options={horseList}
        placeholder="Select a Horse"
        value={activeHorse?.id}
        />
    </div>
  );

  const notesblock = (
    <div css={css.stallNoteSection} className="horseNotesArea">
      <h3>Notes</h3>
      {getNotes(activeHorse?.id)}
    </div>
  );

  const addNoteForm = activeHorse && (
    <div className="stallNoteEntry" css={css.stallNoteEntry}>
      <NoteEntryComponent
        className="stallNoteEntry"
        horse={activeHorse}
        onFetchMore={onFetchMoreNotes}
      />
    </div>
  )

  const btnRequestVet = <RequestVetButton currentHorse={activeHorse}/>;

  const actions = [btnRequestVet];

  const actionsBlock = (
    <div css={css.tray.actions} className="requestVetButton">
      {actions.map((btn, index) => (
        <React.Fragment key={index}>{btn}</React.Fragment>
      ))}
    </div>
  );

  const controlBlock = (
    <div css={css.tray.controls}>
      <div onClick={() => { setFullScreen(fullScreen === "false" ? "true" : "false")}}>
        <Icon icon='FullScreen' fill={appColors?.greens?.base} />
      </div>
      
      {setActiveStall && (
        <div onClick={() => setActiveStall(null)}>
          <Icon icon='X' fill={appColors?.greens?.base} />
        </div>        
      )}
    </div>
  );

  return (
    <div css={css.tray.container}>
      {controlBlock}
      {videoBlock}
      {notesblock}
      {addNoteForm}
      {actionsBlock}
    </div>
  );
}