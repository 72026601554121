import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import Cookies from 'universal-cookie';

import APIClient from '../client';

import AuthContext from "../context/auth-context";

function clearAccessToken() {
  console.log('clear!!!!')
  const cookies = new Cookies();
  cookies.remove('horsewise_token', { path: '/' });
}

const AuthLayout = ({ children }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const barnId = parsedUser?.barn_id;

  const isRefreshingToken = useRef();

  const logout = useCallback(async () => {
    try {
      console.log('Logging out...');
      clearAccessToken();
      localStorage.removeItem('horsewise_refresh');
      localStorage.removeItem('user');
      setUserData(null);
      navigate('/login', {});
    } catch (error) {
      console.error('Logout failed to remove user:', error);
    }
  }, [navigate]);

  const saveUserData = useCallback((res) => {
    const { userData: user, refreshToken } = res;

    window.localStorage.setItem("user", JSON.stringify(user));
    if (refreshToken) {
      window.localStorage.setItem("horsewise_refresh", refreshToken);
    }

    setUserData((state) => ({ ...user }));
  }, []);

  const refresh = useCallback(async (barnId, token) => {
    try {
      const res = await APIClient.post('/auth/refresh', { barnId }, token);
      saveUserData(res);
    } catch (err) {
      // invalid refresh
      if (err.status === 401) {
        logout();
      } else {
        console.error(err);
      }
    }
  }, [logout, saveUserData]);

  async function runProtected(cb, ...args) {
    if (isRefreshingToken.current) {
      await new Promise((resolve) => {
        setInterval(() => {
          if (!isRefreshingToken.current) {
            resolve();
          }
        }, 100);
      });

      return cb(...args);
    }

    // refresh token if expired, then run callback
    isRefreshingToken.current = true;
    if (userData.exp < Date.now() / 1000) {
      const token = window.localStorage.getItem('horsewise_refresh');
      await refresh(barnId, token);
    }
    isRefreshingToken.current = false;

    return cb(...args);
  }

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    let data = window.localStorage.getItem("user");
    if (!data) {
      setUserData(null);
      setIsLoading(false);
      return;
    }

    const loadData = async () => {
      data = JSON.parse(data);

      const token = window.localStorage.getItem('horsewise_refresh');
      await refresh(data?.barn_id, token);

      setUserData(data);
      setIsLoading(false);
    };

    loadData();
  }, [refresh, isLoading]);

  const login = async (values) => {
    if (!values) {
      return;
    }

    try {
      const { refreshToken: token, userData: user } = values;
      localStorage.setItem('horsewise_refresh', token);
      localStorage.setItem('user', JSON.stringify(user));
      setUserData(user);
    } catch (error) {
      console.error('Login failed to parse JSON:', error);
    }
  };

  function selectCompany(user) {
      localStorage.setItem('user', JSON.stringify(user));
      setUserData(user);
  }

  if (isLoading) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        userData,
        login,
        logout,
        selectCompany,
        runProtected,
        refresh
      }}
    >
      <Outlet></Outlet>
    </AuthContext.Provider>
  );
};

export default AuthLayout;
