/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react'
import { css } from './css'
import { appColors } from '../../emotion/utils'
import React, { useState, useEffect } from 'react'
import ModalButtonSet from '../modal-set-button/modal-set-button'
import FormTextInput from '../../components/form-text-input/form-text-input'
import FormTextarea from '../../components/form-textarea/form-textarea'
import FormSelect from '../../components/form-select/form-select-simplified'
import { useMutation } from '../../hooks'
import Client from '../../client'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useQuery } from '../../hooks'
import FormFileUpload from '../../components/form-file-upload';

export default function EditHorseModal({
  setShowModal = () => {},
  open = true,
  onSuccess = () => {},
  horse,
}) {
  const initialValues = {
    name: horse?.name || '',
    assign_stall: horse?.stalls_assigned_to[0]?.stallId || '',
    description: horse?.description || '',
    ferrier_name: horse?.ferrier_name ||'',
    ferrier_phone: horse?.ferrier_phone || '',
    gender: horse?.gender || '',
    image: horse?.avatar?.link
  }

  console.log(horse)

  const handleClose = () => {
    setShowModal(false)
  }

  const [hasError, setHasError] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(open)

  const handleCancel = () => {
    setIsModalOpen(false)
    setShowModal(false)
  }

  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: 'add-horse',
    networkMode: 'always',
    mutationFn: async data => {
      if(horse) {
        const response = Client.put(`/horses/${horse.id}`, data);
        return response;
      } else {
        const response = Client.post(`/horses`, data);
        return response;
      }
    },
    onSuccess: values => {
      setShowModal(false)
      onSuccess(values.body)
    },
    onError: () => {
      console.log("Error")
      setHasError(true)
    },
  })

  const { data: stallData } = useQuery("/stalls");

  const stallOptions = stallData?.data?.map(stall => ({
    value: stall.id,
    label: stall.name,
  }));

  const stallGenders = [
    {
      value: 'MARE',
      label: 'Mare'
    },
    {
      value: 'GELDING',
      label: 'Gelding'
    },
    {
      value: 'STALLION',
      label: 'Stallion'
    }
  ];

  return (
    <Dialog
      header={
        <span css={css.dialogHeader} className="dialog-header">
          {horse ? 'Edit a Horse' : 'Add a Horse'}
        </span>
      }
      visible={isModalOpen}
      style={{ width: '400px' }}
      onHide={handleCancel}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await submitRequest(values)
          } catch (error) {
            console.error(error)
          } finally {
            setSubmitting(false)
          }
        }}
        validationSchema={Yup.object({
          name: Yup.string().label('Horse Name').required(),
        })}
        enabledReinitialize
      >
        {formik => (
          <Form>
            <FormTextInput
              id="name"
              name="name"
              label="Name"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              placeholder="Enter your horse name"
              required
            />
            {formik.errors.horse_name && (
              <div css={css.feedback}>{formik.errors.name}</div>
            )}

            <FormSelect
              formik={formik}
              name="gender"
              label="Gender"
              labelPosition="top"
              required
              placeholder="Select gender"
              options={stallGenders}
              value={formik.values.gender}
            />

            {formik.errors.gender && (
              <div css={css.feedback}>{formik.errors.gender}</div>
            )}

            <FormSelect
              formik={formik}
              name="assign_stall"
              label="Assign Stall"
              labelPosition="top"
              placeholder="Select stall"
              required
              options={stallOptions}
              value={formik.values.assign_stall}
            />
            {formik.ertouchedssign && formik.errors.assign_stall && (
              <div css={css.feedback}>{formik.ertouchedssign && formik.errors.assign_stall}</div>
            )}

            <FormTextInput
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.ferrier_name}
              id="ferrier_name"
              name="ferrier_name"
              label="Ferrier Name"
              placeholder="Enter Ferrier Name"
            />
            
            <FormTextInput
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.ferrier_phone}
              name="ferrier_phone"
              label="Ferrier Phone"
              placeholder="Enter Ferrier Phone"
            />
            
            <FormTextInput
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={(e) => {formik.handleChange(e)}}
              textarea
              id="description"
              name="description"
              label="Description"
              labelPosition="top"
              placeholder="Enter horse notes here..."
            />
            {formik.errors.description && (
              <div css={css.feedback}>{formik.errors.description}</div>
            )}

            <FormFileUpload
              name="image"
              label="Horse Image"
              formik={formik}
              placeholder="Click to add horse image"
              helperText="Accepts png and jpeg"
            />
            
            <ModalButtonSet
              onCancel={handleCancel}
              onSubmit={formik.handleSubmit}
              isSubmitting={formik.isSubmitting}
              submitButtonText="Save changes"
              submitButtonColor={appColors.greens.base}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
