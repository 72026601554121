import {
  appColors,
  flexbox,
  fullWidth,
  padding,
} from '../../emotion/utils'

export const css = {
  navActionBar: {
    ...flexbox('column', 'center', 'center', 24),
    ...fullWidth(),

    '@media screen and (min-width: 600px)': {
      ...flexbox('row', 'center', 'space-between', 12),
    },

    '> span': {
      marginRight: 'auto',
      order: 1,
      paddingLeft: 1,

      '@media screen and (min-width: 600px)': {
        ...flexbox('row', 'center', 'flex-start', 0),
        order: 'unset',
        paddingLeft: 0,
      }
    },

    button: {
      '@media screen and (min-width: 600px)': {
        width: 115,
      },
      '@media screen and (min-width: 768px)': {
        width: 125,
      },
      '@media screen and (min-width: 992px)': {
        width: 144,
      }
    },    
  },
  
  addhorsebtn: {
    color: appColors.greens.base,
    background: appColors.grays[0],
    ":hover": {
      background: appColors.greens.base,
      color: appColors.grays[0],
    },
  },
  
  // tabButton: {
  //   // "&.lt": {
  //   //   borderRadius: '8px 0 0 8px',
  //   //   width: '100px',
  //   //   marginRight: 0,
  //   // },
  //   // "&.rt": {
  //   //   borderRadius: '0 8px 8px 0',
  //   //   borderLeft: '0px',
  //   //   width: '100px',
  //   // },
  //   // "&.active": {
  //   //   color: appColors.grays[900],
  //   //   background: appColors.grays[100],
  //   // },
    
  // },
  
  actionGroup: {
    ...flexbox("row", "center", "flex-end", 8),
    ...fullWidth(),
    order: 0,

    '@media screen and (min-width: 600px)': {
      order: 'unset'
    },

    '> button': {
      flex: 1,

      '@media screen and (min-width: 600px)': {
        flex: 'unset',
      },
    },
  },
}
