import {
  appColors,
  appFonts,
  borderRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  flexbox,
  fontbox,
  fullWidth,
  marginX,
  paddingX,
  paddingXY,
  paddingY,
  posAbsolute,
  posRelative,
  posStatic,
  square,
} from "../../emotion/utils";

export const css = (theme, isFullWidth) => ({
  width: isFullWidth ? "100%" : "auto",

  search: {
    marginBottom: 16,
    textAlign: "right",

    "> input": {
      ...borderRadius(8),
      ...paddingX(14),
      ...paddingY(10),
    },
  },

  ".p-toolbar": {
    ...borderRadius(12),
    marginBottom: 16,
    backgroundColor: theme?.background?.primary,
    border: `1px solid ${theme?.table?.border}`,

    "&-group": {
      "&-start > div, &-end > div": {
        ...flexbox("row", "flex-start", "center", 12),
      },
    },
  },

  ".p-datatable": {

    // Table
    "&-table": {
      backgroundColor: appColors?.grays?.[0],
      border: "none",
    },

    // Thead
    "&-thead": {
      "> tr": {
        "&:hover": {
          backgroundColor: theme?.table?.theadHover,
        },

        "> th": {
          ...paddingX(8),
          ...paddingY(4),
          ...fontbox(appFonts?.inter, 14, 600, 32),
          backgroundColor: appColors?.greens?.base,
          border: "none",
          color: appColors?.grays?.[0],

          "&:first-of-type": {
            ...borderTopLeftRadius(4),
          },
          "&:last-of-type": {
            ...borderTopRightRadius(4),

            "> div": {
              "> span": {
                "> button": {
                  ...borderRadius(6),
                  ...fontbox(appFonts.inter, 12, 700, 20),
                  ...paddingX(12),
                  ...paddingY(0),
                  ...posRelative(0, -16),
                  height: 26,
                },
              },
            },
          },
          "&:only-of-type": {
            "> div": {
              justifyContent: "flex-start",

              "> span": {
                ...posStatic(),
              },
            },
          },
        },
      },
    },

    // Tbody
    "&-tbody": {
      ...flexbox('column', 'flex-start', 'flex-start', 12),

      '@media screen and (min-width: 600px)': {
        display: 'table-row-group',
      },

      '> *': {
        ...fullWidth(),
      },

      "> tr": {
        ...borderRadius(8),
        ...fontbox(appFonts?.inter, 12, 400, 18),
        ...paddingY(8),
        border: `1px solid ${appColors?.grays?.border2}`,
        //backgroundColor: "transparent",
        color: theme?.text?.tertiary,
        overflow: 'hidden',

        '@media screen and (min-width: 600px)': {
          ...borderRadius(0),
          ...paddingY(0),
          border: 'none',
        },

        '&:first-of-type': {
          ...borderTopLeftRadius(0)
        },

        "&:hover": {
          backgroundColor: theme?.table?.theadHover,
        },

        "&.p-rowgroup-header": {
          ...fontbox(appFonts.inter, 16, 700, 24),
          backgroundColor: appColors?.blues?.bgActive,

          "> td": {
            ...posRelative(),
            fontWeight: `700!important`,

            "> button": {
              ...borderRadius(0),
              ...flexbox("row", "center", "flex-end"),
              ...posAbsolute(0, 0, 0, 0),
              ...square("100%"),
              backgroundColor: "transparent",
              paddingRight: 16,
            },
          },
        },

        // "&:last-of-type": {
        //   "> td": {
        //     borderBottom: "none",
        //   },
        // },

        "> td": {
          ...paddingX(8),
          ...paddingY(4),
          borderTop: `1px solid ${appColors?.grays?.border2}50`,
          borderBottom: `1px solid ${appColors?.grays?.border2}50`,

          '@media screen and (min-width: 600px)': {
            borderTop: 'none',
            borderBottom: '1px solid #e9ecef',
          },

          "&:last-of-type": {
            // borderBottom: 0,
            ...paddingY(0),

            "> div": {
              ...marginX('auto'),
              borderLeft: `1px solid ${appColors?.grays?.border2}50`,
              gap: 0,

              '@media screen and (min-width: 600px)': {
                borderLeft: 'none',
              },

              "> div:not(.p-checkbox-box)": {
                ...borderRadius(0),
                ...paddingXY(10),
                borderRight: `1px solid ${appColors?.grays?.border2}50`,
                display: "inline-flex",
                width: "auto",

                '@media screen and (min-width: 600px)': {
                  borderRight: 'none',
                },
              },
            },
          },

          "input:not([type='checkbox'])": {
            textAlign: "right",
            width: 75,
          },
        },
      },
    },
  },

  actions: {
    ...flexbox("row", "center", "flex-end", 4),
    marginLeft: "auto",
  },

  helper: {
    ...fontbox(appFonts.inter, 10, 400, 10),
    display: "block",
  },
});
