import { appColors } from "../../emotion/utils";

export const LoginLogo = ({
  fill = appColors?.grays?.[1],
  style
}) => (
    <svg width="190" height="48" viewBox="0 0 190 48" fill="none" style={style}>
      <path d="M181.353 32C179.13 32 177.463 31.4981 176.351 30.4944C175.24 29.4694 174.684 27.9531 174.684 25.9457V21.9735C174.684 19.838 175.23 18.2256 176.321 17.1365C177.432 16.026 179.099 15.4708 181.322 15.4708C183.504 15.4708 185.15 16.0046 186.262 17.0724C187.373 18.1402 187.929 19.6992 187.929 21.7493V24.2479C187.929 24.5042 187.805 24.6323 187.558 24.6323H178.296V25.5933C178.296 26.7679 178.533 27.6328 179.006 28.188C179.5 28.7219 180.293 28.9889 181.384 28.9889C182.228 28.9889 182.876 28.85 183.329 28.5724C183.802 28.2948 184.039 27.8784 184.039 27.3231C184.039 27.0669 184.162 26.9387 184.409 26.9387H187.249C187.476 26.9387 187.599 27.0562 187.62 27.2911C187.682 28.8287 187.167 30.0032 186.076 30.8148C185.006 31.6049 183.432 32 181.353 32ZM178.296 22.0696H184.347V21.9095C184.347 20.7349 184.1 19.87 183.607 19.3148C183.113 18.7595 182.361 18.4819 181.353 18.4819C180.303 18.4819 179.531 18.7702 179.037 19.3468C178.543 19.902 178.296 20.7669 178.296 21.9415V22.0696Z" fill={fill} />
      <path d="M166.45 32C164.413 32 162.91 31.5836 161.943 30.7507C160.996 29.9178 160.554 28.6258 160.616 26.8746C160.616 26.6397 160.739 26.5223 160.986 26.5223H163.765C163.97 26.5223 164.073 26.6397 164.073 26.8746C164.073 27.6435 164.269 28.2094 164.66 28.5724C165.051 28.9355 165.648 29.117 166.45 29.117C167.294 29.117 167.922 28.9782 168.334 28.7006C168.745 28.4229 168.951 27.9958 168.951 27.4192C168.951 27.0135 168.817 26.6718 168.55 26.3941C168.282 26.0952 167.85 25.8175 167.253 25.5613L163.734 24.0237C162.663 23.5539 161.892 22.9986 161.418 22.3579C160.965 21.7173 160.739 20.8844 160.739 19.8593C160.739 18.4071 161.192 17.318 162.097 16.5919C163.024 15.8445 164.444 15.4708 166.358 15.4708C168.313 15.4708 169.785 15.8552 170.773 16.624C171.761 17.3928 172.255 18.5139 172.255 19.9875C172.255 20.2224 172.131 20.3398 171.884 20.3398H169.106C169.003 20.3398 168.91 20.3078 168.828 20.2437C168.766 20.1583 168.735 20.0195 168.735 19.8273C168.735 19.3575 168.54 18.9944 168.148 18.7382C167.757 18.4605 167.212 18.3217 166.512 18.3217C165.792 18.3217 165.257 18.4499 164.907 18.7061C164.557 18.9624 164.382 19.3468 164.382 19.8593C164.382 20.2437 164.485 20.5427 164.691 20.7563C164.917 20.9698 165.349 21.2154 165.987 21.493L169.507 23.0306C170.598 23.5005 171.38 24.0664 171.853 24.7284C172.347 25.3691 172.594 26.2126 172.594 27.2591C172.594 28.818 172.069 30.0032 171.02 30.8148C169.97 31.6049 168.447 32 166.45 32Z" fill={fill} />
      <path d="M154.561 31.6156C154.334 31.6156 154.221 31.4875 154.221 31.2312V16.2396C154.221 15.9833 154.334 15.8552 154.561 15.8552H157.494C157.741 15.8552 157.864 15.9833 157.864 16.2396V31.2312C157.864 31.4875 157.741 31.6156 157.494 31.6156H154.561ZM156.043 13.5808C155.343 13.5808 154.808 13.3886 154.437 13.0042C154.087 12.6198 153.912 12.1286 153.912 11.5306V11.0501C153.912 10.4308 154.087 9.93965 154.437 9.5766C154.808 9.1922 155.343 9 156.043 9C156.742 9 157.267 9.1922 157.617 9.5766C157.967 9.93965 158.142 10.4308 158.142 11.0501V11.5306C158.142 12.1286 157.967 12.6198 157.617 13.0042C157.267 13.3886 156.742 13.5808 156.043 13.5808Z" fill={fill} />
      <path d="M132.33 31.6156C132.124 31.6156 131.991 31.5088 131.929 31.2953L126.464 11.0501C126.382 10.7725 126.495 10.6337 126.804 10.6337H129.799C130.025 10.6337 130.159 10.7405 130.2 10.954L133.164 22.422C133.328 22.9772 133.462 23.5645 133.565 24.1838C133.689 24.8032 133.802 25.4118 133.905 26.0097C134.028 26.5863 134.141 27.1309 134.244 27.6434H134.306C134.512 26.896 134.718 26.1592 134.924 25.4331C135.129 24.6857 135.345 23.9383 135.572 23.1908L137.918 15.3746L136.776 11.4666C136.735 11.3384 136.745 11.2423 136.807 11.1783C136.889 11.0928 136.982 11.0501 137.085 11.0501H140.11C140.316 11.0501 140.44 11.1569 140.481 11.3705L143.568 23.2549C143.774 23.981 143.959 24.7177 144.124 25.4652C144.288 26.1913 144.453 26.9174 144.618 27.6434H144.648C144.751 27.1309 144.854 26.565 144.957 25.9457C145.081 25.3264 145.204 24.7071 145.328 24.0877C145.451 23.4471 145.575 22.8491 145.698 22.2939L148.538 10.954C148.58 10.7405 148.713 10.6337 148.94 10.6337H152.027C152.315 10.6337 152.418 10.7725 152.336 11.0501L147.026 31.2953C146.964 31.5088 146.83 31.6156 146.624 31.6156H142.92C142.693 31.6156 142.549 31.5088 142.487 31.2953L140.697 24.6964C140.491 23.9489 140.285 23.2015 140.079 22.454C139.894 21.7066 139.719 20.9805 139.554 20.2758H139.493C139.287 20.9805 139.081 21.6959 138.875 22.422C138.69 23.1481 138.484 23.8849 138.258 24.6323L136.313 31.3273C136.272 31.4341 136.22 31.5088 136.158 31.5515C136.097 31.5942 136.014 31.6156 135.911 31.6156H132.33Z" fill={fill} />
      <path d="M118.429 32C116.206 32 114.539 31.4981 113.428 30.4944C112.316 29.4694 111.761 27.9531 111.761 25.9457V21.9735C111.761 19.838 112.306 18.2256 113.397 17.1365C114.508 16.026 116.175 15.4708 118.398 15.4708C120.58 15.4708 122.226 16.0046 123.338 17.0724C124.449 18.1402 125.005 19.6992 125.005 21.7493V24.2479C125.005 24.5042 124.881 24.6323 124.634 24.6323H115.373V25.5933C115.373 26.7679 115.609 27.6328 116.083 28.188C116.577 28.7219 117.369 28.9889 118.46 28.9889C119.304 28.9889 119.952 28.85 120.405 28.5724C120.878 28.2948 121.115 27.8784 121.115 27.3231C121.115 27.0669 121.238 26.9387 121.485 26.9387H124.326C124.552 26.9387 124.676 27.0562 124.696 27.2911C124.758 28.8287 124.243 30.0032 123.153 30.8148C122.082 31.6049 120.508 32 118.429 32ZM115.373 22.0696H121.424V21.9095C121.424 20.7349 121.177 19.87 120.683 19.3148C120.189 18.7595 119.438 18.4819 118.429 18.4819C117.379 18.4819 116.608 18.7702 116.114 19.3468C115.62 19.902 115.373 20.7669 115.373 21.9415V22.0696Z" fill={fill} />
      <path d="M103.527 32C101.489 32 99.9866 31.5836 99.0193 30.7507C98.0725 29.9178 97.63 28.6258 97.6918 26.8746C97.6918 26.6397 97.8153 26.5223 98.0622 26.5223H100.841C101.047 26.5223 101.15 26.6397 101.15 26.8746C101.15 27.6435 101.345 28.2094 101.736 28.5724C102.127 28.9355 102.724 29.117 103.527 29.117C104.371 29.117 104.998 28.9782 105.41 28.7006C105.822 28.4229 106.027 27.9958 106.027 27.4192C106.027 27.0135 105.894 26.6718 105.626 26.3941C105.359 26.0952 104.926 25.8175 104.329 25.5613L100.81 24.0237C99.7397 23.5539 98.9678 22.9986 98.4945 22.3579C98.0417 21.7173 97.8153 20.8844 97.8153 19.8593C97.8153 18.4071 98.2681 17.318 99.1737 16.5919C100.1 15.8445 101.52 15.4708 103.434 15.4708C105.389 15.4708 106.861 15.8552 107.849 16.624C108.837 17.3928 109.331 18.5139 109.331 19.9875C109.331 20.2224 109.207 20.3398 108.96 20.3398H106.182C106.079 20.3398 105.986 20.3078 105.904 20.2437C105.842 20.1583 105.811 20.0195 105.811 19.8273C105.811 19.3575 105.616 18.9944 105.225 18.7382C104.834 18.4605 104.288 18.3217 103.588 18.3217C102.868 18.3217 102.333 18.4499 101.983 18.7061C101.633 18.9624 101.458 19.3468 101.458 19.8593C101.458 20.2437 101.561 20.5427 101.767 20.7563C101.993 20.9698 102.426 21.2154 103.064 21.493L106.583 23.0306C107.674 23.5005 108.456 24.0664 108.929 24.7284C109.423 25.3691 109.67 26.2126 109.67 27.2591C109.67 28.818 109.146 30.0032 108.096 30.8148C107.046 31.6049 105.523 32 103.527 32Z" fill={fill} />
      <path d="M88.1096 31.6156C87.8832 31.6156 87.77 31.4875 87.77 31.2312V19.7953C87.77 19.1546 87.7495 18.546 87.7083 17.9694C87.6877 17.3928 87.6363 16.8268 87.5539 16.2716C87.5128 15.994 87.6363 15.8552 87.9244 15.8552H90.5177C90.6412 15.8552 90.7338 15.8765 90.7956 15.9192C90.8573 15.9619 90.8985 16.0474 90.9191 16.1755C91.0014 16.5599 91.0631 16.9977 91.1043 17.4889C91.1455 17.98 91.166 18.4178 91.166 18.8022L91.413 20.6602V31.2312C91.413 31.4875 91.2895 31.6156 91.0425 31.6156H88.1096ZM91.0734 22.0696L90.8882 18.8022C91.3204 18.1616 91.8041 17.5956 92.3392 17.1045C92.8949 16.5919 93.4506 16.1968 94.0063 15.9192C94.5826 15.6202 95.1075 15.4708 95.5808 15.4708C95.9307 15.4708 96.2086 15.5028 96.4144 15.5669C96.5173 15.6096 96.5894 15.663 96.6305 15.727C96.6717 15.7911 96.6923 15.8765 96.6923 15.9833C96.7334 16.4958 96.7437 17.0511 96.7231 17.649C96.7231 18.2256 96.6923 18.7702 96.6305 19.2827C96.6099 19.4109 96.5585 19.507 96.4762 19.571C96.4144 19.6137 96.3115 19.6244 96.1674 19.6031C96.0439 19.5817 95.8896 19.5604 95.7043 19.539C95.5191 19.5176 95.3236 19.507 95.1178 19.507C94.665 19.507 94.1916 19.6137 93.6976 19.8273C93.2242 20.0195 92.7611 20.3078 92.3083 20.6922C91.8761 21.0766 91.4645 21.5357 91.0734 22.0696Z" fill={fill} />
      <path d="M78.0461 32C75.8644 32 74.1767 31.4661 72.983 30.3983C71.8098 29.3092 71.2232 27.6648 71.2232 25.4652V22.0056C71.2232 19.8273 71.8098 18.1936 72.983 17.1045C74.1561 16.0153 75.8438 15.4708 78.0461 15.4708C80.2278 15.4708 81.9052 16.0153 83.0783 17.1045C84.2515 18.1936 84.8381 19.8273 84.8381 22.0056V25.4652C84.8381 27.6648 84.2515 29.3092 83.0783 30.3983C81.9258 31.4661 80.2483 32 78.0461 32ZM78.0461 28.7646C79.1575 28.7646 79.9602 28.4763 80.4542 27.8997C80.9481 27.3018 81.1951 26.3728 81.1951 25.1128V22.3579C81.1951 21.098 80.9481 20.1797 80.4542 19.6031C79.9602 19.0051 79.1575 18.7061 78.0461 18.7061C76.9347 18.7061 76.1217 19.0051 75.6071 19.6031C75.1132 20.1797 74.8662 21.098 74.8662 22.3579V25.1128C74.8662 26.3728 75.1132 27.3018 75.6071 27.8997C76.1217 28.4763 76.9347 28.7646 78.0461 28.7646Z" fill={fill} />
      <path d="M52.2683 31.6156C52.0419 31.6156 51.9287 31.4875 51.9287 31.2312V11.0181C51.9287 10.7618 52.0419 10.6337 52.2683 10.6337H55.263C55.5099 10.6337 55.6334 10.7618 55.6334 11.0181V19.2507H64.2778V11.0181C64.2778 10.7618 64.4013 10.6337 64.6483 10.6337H67.6429C67.8693 10.6337 67.9825 10.7618 67.9825 11.0181V31.2312C67.9825 31.4875 67.8693 31.6156 67.6429 31.6156H64.6483C64.4013 31.6156 64.2778 31.4875 64.2778 31.2312V22.5822H55.6334V31.2312C55.6334 31.4875 55.5099 31.6156 55.263 31.6156H52.2683Z" fill={fill} />
      <path d="M42.5795 24.7211C42.2119 20.7528 39.064 15.94 37.2649 13.8146C39.2989 19.3687 39.0621 24.2576 34.8644 30.0186C32.0958 33.8183 27.8406 36.7289 24.385 38.4203C19.995 40.569 17.8603 42.466 17.1145 43.4291C15.8855 45.016 15.9604 47.2329 16.1411 48C16.8796 45.9169 18.2335 45.3248 19.4884 44.5154C21.6537 43.1188 24.7555 42.8997 28.2261 41.5708C31.5978 40.2798 33.3446 38.3095 34.9972 36.3197C33.0245 41.7054 29.2744 44.1885 27.6459 44.7568C28.6351 45.1899 33.1484 43.2976 35.9576 41.0426C40.2861 37.568 43.1904 31.3164 42.5795 24.7211Z" fill={fill} />
      <path d="M33.0678 10.3154C29.8678 6.87645 24.6385 5.6568 22.5799 5.55193C22.2358 4.16715 20.7273 3.17189 19.4987 2.47813C18.4017 1.85869 17.7822 0.612791 17.1754 0C16.4119 3.58545 17.5215 4.83798 17.6874 5.21299C17.0309 5.75697 12.611 8.96934 11.7166 9.70691C11.2382 11.2607 10.5792 12.0473 9.95473 13.1329C9.3303 14.2185 3.95889 19.1292 2.08336 20.7808C0.207842 22.4323 1.13911 23.5991 1.31495 23.9275C1.49078 24.2559 1.11159 25.3441 1.7754 25.9201C2.82219 26.8285 6.79083 27.9901 8.8775 26.9628C9.40292 25.4188 9.98014 25.2473 10.872 24.7524C11.8231 24.2246 16.867 24.2999 19.1932 23.7635C21.5194 23.2271 21.7107 22.5294 22.2902 20.7617C22.8424 19.0774 22.759 17.4066 22.6675 16.2204C24.6835 19.218 22.8596 23.1661 22.384 23.5532C22.9831 25.8179 21.0279 28.7659 17.9237 31.5717C14.8195 34.3774 13.5241 37.7548 13.29 39.943C13.133 41.4108 13.3834 44.5824 13.8861 46.1461C14.7168 43.1552 15.7264 42.1035 16.3972 41.4047L16.426 41.3748C17.4368 40.321 19.2809 39.0267 24.4109 36.4253C26.4498 35.3913 30.8845 32.5711 33.1094 29.9711C35.4557 27.2293 36.748 23.3549 37.0173 21.5457C37.4738 18.4798 36.7543 14.2771 33.0678 10.3154Z" fill={fill} />
      <path d="M8.25045 28.3549C8.53731 32.1274 10.0764 35.5542 12.4578 38.2297C12.3297 38.7426 12.2438 39.2282 12.1959 39.6763C12.1791 39.8337 12.1667 40.0035 12.1584 40.1836C9.05897 37.1484 7.02994 33.0454 6.68144 28.4782C7.20549 28.4869 7.73763 28.4513 8.25045 28.3549Z" fill={fill} />
      <path d="M15.8425 2.85677H8.20177V13.3306C8.12781 13.4085 8.04994 13.4895 7.96836 13.5735C7.57813 13.9751 7.1231 14.4054 6.62789 14.8702V1.29968H15.8458C15.7987 1.88001 15.8025 2.39632 15.8425 2.85677Z" fill={fill} />
      <path d="M22.5933 2.85677H42.3548V19.0483C43.0862 20.5818 43.6899 22.2375 43.9287 23.8492V1.29968H20.3487C20.3511 1.30102 20.3535 1.30236 20.3559 1.3037C20.9779 1.65461 21.7284 2.07799 22.3772 2.65314C22.4496 2.71738 22.5218 2.78539 22.5933 2.85677Z" fill={fill} />
    </svg>
  );

  