import {
  appColors,
  posFixed,
  posRelative
} from '../../emotion/utils';

export const css = {
  // '&::before': {
  //   ...posFixed(0, 0, null, 0, -1),
  //   backgroundColor: appColors?.greens?.base,
  //   content: '""',
  //   height: '25%'
  // },

  main: {
    ...posRelative(),
    backgroundColor: appColors?.grays?.[0],
    paddingTop: 60,
  }
};
