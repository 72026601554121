/** @jsxImportSource @emotion/react */

import Icon from "../../components/icon/icon";
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import moment from "moment";

import { css } from "./css";
import { appColors } from "../../emotion/utils";

const StallCard = (props) => {
  const { data, onClick, setActiveStall, isActive, id } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const horses = data.horses_assigned.map(h => {return h.horse.name}).slice(0, 2).join(' | ');

  const headerBlock = (
    <div css={css.cardHeader}>
      <h1>{data?.name}</h1>
      <span>{horses}</span>
    </div>
  );

  const horseImg = data?.image;

  const imgBlock = horseImg
    ? (
      <div css={css.stallCard().imgBlock}>
        <img src={horseImg?.link} alt={data?.name || ""} />
      </div>
    ) : (
      <div css={css.stallCard().plc}>
        <Icon icon="Horse2" fill={appColors?.grays?.[200]} />
      </div>
    );

  // const tagsBlock = <Tags />;

  const onClickStall = () => {
    setActiveStall(data)
  };

  return (
    <div id={id} css={css.stallCard(isActive)} className="stallcard"  ref={setNodeRef} style={style} {...listeners} {...attributes}>
      <div css={css.stallCard(isActive).holder} onClick={onClickStall}>
        {headerBlock}
        {imgBlock}
        
        {data?.image?.created_at && <div css={css.stallCard(isActive).holder.date} style={{fontSize:"0.7em"}}>{moment(data?.image?.created_at).format("MM/DD/YYYY HH:mm")}</div>}

        {/*{tagsBlock}*/}
      </div>
    </div>
  );

};
export default StallCard;