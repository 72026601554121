import {
  appFonts,
  borderRadius,
  darken,
  fontbox,
  paddingX,
  paddingY,
  transition,
} from "../../emotion/utils";

export const css = (theme, color) => ({
  container: {
    ...borderRadius(8),
    ...fontbox(appFonts?.inter, 12, 700, 20),
    ...paddingX(16),
    ...paddingY(10),
    ...transition(),
    backgroundColor:
      theme?.button?.[color]?.background || theme?.button?.primary?.background,
    border: `1px solid ${
      theme?.button?.[color]?.border || theme?.button?.primary?.border
    }`,
    color: theme?.button?.[color]?.color || theme?.button?.primary?.color,
    cursor: "pointer",

    "@media screen and (min-width: 600px)": {
      ...fontbox(appFonts?.inter, 14, 700, 14),
    },
    // "@media screen and (min-width: 600px)": {
    //   ...fontbox(appFonts?.inter, 16, 700, 24),
    // },

    "&:hover": {
      backgroundColor: darken(theme?.button?.[color]?.background, -0.25),
    },

    link: {
      ...borderRadius(0),
      ...paddingX(0),
      ...paddingY(0),
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "unset",
      color: theme.button[color].border || theme.button.primary.border,
      flex: "unset",
      marginRight: "auto",
      width: "auto",

      "&:hover": {
        backgroundColor: "transparent",
        color: darken(theme.button[color].border, -0.25),
      },
    },
  },
});