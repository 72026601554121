import React, { useRef } from "react";

import DragAndDrop from "../drag-ndrop/drag-ndrop";

export default function FormFileUpload(props) {
  const {
    formik,
    name,
    label,
    placeholder,
    displayType = "image",
    accept = ".png,.jpeg,.jpg",
    helperText = "Only PNG and JPEG images are accepted.",
  } = props;

  const inputRef = useRef();

  const errorClass = formik.errors[name] && formik.touched[name] ? "error" : "";

  async function onFileChange(file) {
    if (!file) return;

    await formik.setFieldValue(`${name}`, file, true);
    await formik.validateForm();
  }

  return (
    <div className="form-group-item">
      <div
        className="form-group-item__helper-text"
        style={{
          alignItems: "flex-end",
        }}
      >
        <label
          htmlFor={name}
          style={{
            textWrap: "nowrap",
          }}
        >
          {label}
        </label>
        <div>{helperText}</div>
      </div>
      <div className="avatar-block">
        <input
          name={name}
          accept={accept}
          id={name}
          type="file"
          ref={inputRef}
          className={errorClass}
          hidden
          onChange={(e) => onFileChange(e.target.files[0])}
        />
      </div>

      {displayType === "image" && (
        <DragAndDrop
          className={errorClass}
          onChange={onFileChange}
          onClick={() => inputRef.current.click()}
          file={formik.values[name]}
          placeholder={formik.values[`${name}Url`] || placeholder}
        />
      )}
    </div>
  );
}
