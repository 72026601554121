import {
  appColors,
  appFonts,
  dimensions,
  flexbox,
  fontbox,
  fullHeight,
  fullWidth,
  paddingX,
  paddingY,
  posAbsolute,
  posRelative,
  posStatic,
  square,
} from '../../emotion/utils';

export const css = {
  navigation: {
    ...flexbox('column', 'flex-start', 'flex-start', 7),
    ...posAbsolute(60, 0, null, 0, 100),
    boxShadow: '0px 1px 4px 0px rgba(16, 24, 40, 0.25)',
    display: 'none',

    '@media screen and (min-width: 600px)': {
      ...posStatic(),
      display: 'flex!important',
    },

    '&.open': {
      display: 'flex',
    },

    '> ul': {
      ...flexbox('column', 'flex-start', 'flex-start', 7),
      ...fullWidth(),
      ...paddingY(8),
      backgroundColor: appColors?.greens?.base,

      '@media screen and (min-width: 600px)': {
        ...flexbox('row', 'center', 'flex-start', 0),
        ...paddingY(0),
      },

      li: {
        ...flexbox('row', 'center', 'flex-start', 7),
        ...fontbox(appFonts?.inter, 14, 400, 14),
        ...fullWidth(),
        ...paddingY(10),
        ...paddingX(17),
        color: appColors?.greens?.textlight,
        cursor: 'pointer',

        '@media screen and (min-width: 600px)': {
          ...fontbox(appFonts?.inter, 18, 600, 60),
          ...paddingY(0),
          ...paddingX(24),
        },

        // Icon
        '> div': {
          '@media screen and (min-width: 600px)': {
            display: 'none',
          },

          '> svg': {
            ...square(18),
          },
        },

        // Last li
        '&:last-of-type': {
          '@media screen and (min-width: 600px)': {
            paddingRight: 0,
          },

          // Icon
          '> div': {
            '@media screen and (min-width: 600px)': {
              display: 'block',
            },

            '>svg': {
              '@media screen and (min-width: 600px)': {
                ...dimensions(32, 32),
              },
            },
          },

          // Text
          '> span': {
            '@media screen and (min-width: 600px)': {
              display: 'none',
            },
          },
        },

        '&:hover': {},

        '&:not(.logout, .active):hover': {
          // color: appColors.greens.textdark,
          // padding: '6px 0 0',
          // backgroundColor: appColors.greens.hightight,
          // //darken(theme.button.primary.background, -0.25),
          // borderBottom: `6px solid ${appColors.reds.secondary}`,
        },

        '&.active': {
          ...posRelative(),
          backgroundColor: appColors?.greens?.navbuttonactivebg,
          color: appColors?.grays?.[0],

          // border
          '&::before': {
            ...posAbsolute(0, null, 0, 0),
            ...fullHeight(),
            backgroundColor: appColors?.reds?.secondary,
            content: '""',
            width: 6,

            '@media screen and (min-width: 600px)': {
              ...fullWidth(),
              ...posAbsolute('auto', 0, 0, 0),
              height: 6,
            },
          },
        },
      },
    },
  },

  mobTriggerContain: {
    ...flexbox('row', 'flex-start', 'flex-start', 0),
    ...square(40),

    '@media screen and (min-width: 600px)': {
      display: 'none',
    },

    menu: {
      ...flexbox('row', 'center', 'center', 0),
      ...square(40),
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      padding: 0,
    },
  },
}
