import { useTheme } from "@emotion/react";
import { Formik, Form } from "formik";
import { useMutation } from "../../hooks";
import signupSchema from "../../schemas/signupSchema";
import { css } from "./css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AuthContext from "../../context/auth-context";
import { useContext } from "react";
import { FormElement } from "../../components/form-element/form-element";
import Client from "../../client";
import Button from "../../components/button/button";

const initialValues = {
  email: "",
  password: "",
  password_confirmation: ""
};

const validate = () => ({});

const Signup = () => {
  const { register } = useContext(AuthContext);
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const theme = useTheme();

  const form = (formik) => (
    <div css={css.formBlock()}>
      <div css={css.formBlock().intro(theme)}>
        <h1>Register for an account</h1>
      </div>

      <div css={css.formBlock().elements}>
        <FormElement
          element="input"
          elementType="text"
          id="email"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
          label="Email"
        />

        <FormElement
          element="input"
          elementType="text"
          id="first_name"
          name="first_name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
          label="First Name"
        />

        <FormElement
          element="input"
          elementType="text"
          id="last_name"
          name="last_name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
          label="Last Name"
        />

        <FormElement
          element="input"
          elementType="text"
          id="password"
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
          label="Password"
        />

        <FormElement
          element="input"
          elementType="text"
          id="password_confirmation"
          name="password_confirmation"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
          label="Password Confirmation"
        />

        <Button
          label="Register"
          onClick={formik.handleSubmit}
          loading={isLoading}
          disabled={isLoading || Object.values(formik.errors).length || hasError}
          type="submit"
        />
      </div>
    </div>
  );

  const { mutateAsync: onSubmit, isLoading } = useMutation({
    mutationKey: "register_user",
    mutationFn: async (data) => Client.post("/auth/register", data),
    onSuccess: (data) => {
      register(data);
    },
    onError: () => {
      setHasError(true);
    },
    isProtected: false
  });

  return (
    <div css={css.container(theme)}>
      <div css={css.block}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
        >
          {(formik) => (
            <Form>
              {form(formik)}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Signup;