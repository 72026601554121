/** @jsxImportSource @emotion/react */

import React, { useState, useEffect } from "react";
import { changeCurrentCamera, selectedCurrentCamera } from "../../store/cameraSlice";
import { useAppDispatch, useAppSelector } from "../../shared/model";
import VideoPlayer from "../video-player-old/videoplayer";
import { css } from "./css";
import Icon from "../icon/icon";


export default function VideoComponent(props) {

  const {
    currentCamera,
    full_screen = false
  } = props;

//  const [cameraToShow, setCameraToShow] = useState({});

  const initStatus = "Real times snapshot, loading camera stream";

  const [status, setStatus] = useState(initStatus);
  const [wsUrl, setWsUrl] = useState(null);


  // useEffect(() => {
  //   setCameraToShow(currentCamera);
  // }, [currentCamera]);

  useEffect(() => {
    if (currentCamera && currentCamera.id) {
      const wsUrl = window.location.hostname === 'localhost'
      ? 'ws://localhost:3001'
      : `wss://${window.location.host}`
      const currentCameraLink = `${wsUrl}/api/camera/${currentCamera?.id}`;
      setWsUrl(currentCameraLink);
    }
  }, [currentCamera]);


  return (
    <div css={css.videocard}>
      <VideoPlayer
        url={currentCamera?.id ? `${wsUrl}` : null} 
        status={status}
        setStatus={setStatus}
        fullScreen={full_screen}
        //id={currentCamera?.id}
      />
      <p>{status}</p>
    </div>
  );
}