/** @jsxImportSource @emotion/react */

import { css } from './css'
import React from 'react'
import { useField } from 'formik'
import { Checkbox } from 'primereact/checkbox'

const FormCheckBox = ({ label, groupLabel, ...props }) => {
  return (
    <>
      {groupLabel && <div css={css.groupLabel}>{groupLabel}</div>}
      <div css={css.container}>
        <Checkbox
          inputId={props.id || props.name}
          {...props}
          checked={props.formik?.values[props.name] == 1}
          onChange={(e) => props.formik?.handleChange(e)}
        />
        <label htmlFor={props.id || props.name}>{label}</label>
      </div>
    </>
  )
}

export default FormCheckBox
