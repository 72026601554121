/** @jsxImportSource @emotion/react */

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TOP_LEVEL_PAGES } from "../constants";

import AuthContext from "../../context/auth-context";

import MobTrigger from './menu-trigger';

import IconSignOut from "../icons/icon-signout";

import { css } from "./css";
import Icon from "../icon/icon";

function Menu({
  activePage,
  setActivePage,
  navOpen,
  setNavOpen,
  hideMenu
}) {
  const navigate = useNavigate();

  const { logout, userData } = useContext(AuthContext);

  const trigger = <MobTrigger navOpen={navOpen} setNavOpen={setNavOpen} />;

  const getIcon = (icon) => {
    if (!icon) return null;

    return <Icon icon={icon} />;
  }

  const pageEls = !hideMenu
    ? TOP_LEVEL_PAGES
      .filter(p => userData.is_admin || p.visibility.find(r => userData[r]))
      .map((page) => {
        return (
          <li
            key={page?.path}
            onClick={() => {
              setNavOpen(false);
              navigate(`/${page?.path}`);
              setActivePage(page?.path);
            }}
            className={activePage === page?.path ? "active" : ""}
          >
            {getIcon(page?.icon)}
            {page?.display}
          </li>
        );
      })
    : null;

  // const navigation = navOpen && (
  const navigation = (
    <nav css={css?.navigation} className={`main-menu ${navOpen ? "open" : ""}`}>
      <ul>
        {pageEls}

        <li className="logout" onClick={logout}>
          <Icon icon="Signout" />
          <span>Logout</span>
          {/* <CustomButton
            label="Log Out"
            color="white"
            type="outlined"
            sty={{
              lineHeight: "26px",
              paddingTop: 0,
              paddingLeft: 20,
              paddingBottom: 0,
              paddingRight: 20,
            }}
          /> */}
        </li>
      </ul>
    </nav>
  );

  return (
    <>
      {trigger}
      {navigation}
    </>
  );
}

export default Menu;
