import {
  appFonts,
  borderRadius,
  darken,
  flexbox,
  fontbox,
  fullWidth,
  marginAuto,
  posAbsolute,
  posRelative,
  themes,
  transition,
} from "../../emotion/utils";

export const css = {
  container: (theme) => ({
    backgroundColor: theme?.background?.primary,
    minHeight: "100vh",
    paddingTop: 32,

    "@media screen and (min-width: 576px)": {
      paddingTop: 64,
    },
  }),

  block: {
    ...marginAuto(),
    maxWidth: 360,
    width: "80%",

    "> form": {
      ...flexbox("column", "center", "flex-start", 32),
      ...posRelative(),

      "> *": {
        ...fullWidth(),
        textAlign: "center",
      },

      "> svg": {
        width: 150,

        "@media screen and (min-width: 576px)": {
          ...fullWidth()
        }
      }
    },
  },

  formBlock: {
    ...flexbox("column", "center", "flex-start", 24),

    "> *": {
      ...fullWidth(),
    },

    reset: {
      ...posAbsolute(80, 0, null, 0, 1),
    },

    intro: (theme) => ({
      ...flexbox("column", "center", "center", 12),

      h1: {
        ...fontbox(appFonts?.inter, 20, 600, 28),
        color: theme?.text?.primary,

        "@media screen and (min-width: 576px)": {
          ...fontbox(appFonts?.inter, 30, 600, 38),
        },
      },

      p: {
        ...fontbox(appFonts?.inter, 12, 400, 20),
        color: theme?.text?.tertiary,

        "@media screen and (min-width: 576px)": {
          ...fontbox(appFonts?.inter, 16, 400, 24),
        },
      },
    }),

    forgotlink: (theme) => ({
      ...flexbox("column", "start", "center", 12),

      "> span": {
        ...fontbox(appFonts?.inter, 12, 600, 20),
        color: theme?.text?.primary,

        "@media screen and (min-width: 576px)": {
          ...fontbox(appFonts?.inter, 14, 600, 24),
        },

        "&:hover": {
          color: darken(theme?.button?.primary?.background, -0.25),
          cursor: "pointer",
        },
      },
    }),

    elements: {
      ...flexbox("column", "center", "flex-start", 20),

      "> *": {
        ...fullWidth(),
      },
    },
  },

  errorBox: (theme) => ({
    ...borderRadius(12),
    ...flexbox("column", "center", "space-between", 55),
    ...posAbsolute(null, -12, -12, -12, 1),
    backgroundColor: theme?.background?.primary,
    border: `2px solid ${theme?.button?.danger?.background}`,
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.25)",
    minHeight: 300,
    padding: 10,
    width: "calc(100% + 20px)!important",

    msg: {
      ...flexbox("column", "center", "center", 8),

      h2: {
        ...fontbox(appFonts?.inter, 30, 600, 38),
      },

      p: {
        ...fontbox(appFonts?.inter, 16, 400, 24),
        color: theme?.text?.primary,

        "@media (min-width: 768px)": {
          ...fontbox(appFonts?.inter, 20, 400, 28),
        },
      },
    },

    btn: {
      ...fullWidth(),
    },
  }),

  account: (theme) => ({
    ...fontbox(themes?.fonts?.inter, 12, 400, 20),
    color: themes?.text?.tertiary,

    "@media screen and (min-width: 576px)": {
      ...fontbox(themes?.fonts?.inter, 14, 400, 20),
    },

    "> span": {
      ...fontbox(themes?.fonts?.inter, 12, 600, 20),
      ...transition(),
      color: theme?.button?.primary?.background,
      cursor: "pointer",

      "@media screen and (min-width: 576px)": {
        ...fontbox(appFonts?.inter, 14, 600, 20),
      },
    },
  }),

  signup: (theme) => ({
    ...fontbox(appFonts?.inter, 14, 400, 20),

    "> span": {
      ...fontbox(appFonts?.inter, 14, 700, 20),
      color: theme?.text?.primary,

      "@media screen and (min-width: 576px)": {
        ...fontbox(appFonts?.inter, 14, 700, 24),
      },
      "&:hover": {
        color: darken(theme?.button?.primary?.background, -0.25),
        cursor: "pointer",
      },
    },
  }),
};
