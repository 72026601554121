import React, { useMemo, useContext } from "react";

import { Formik, Form, ErrorMessage } from "formik";
import FormTextInput from "../../components/form-text-input/form-text-input";
import { Dialog } from 'primereact/dialog';
import { useMutation } from '../../hooks';

import { css } from "./css";
import ModalButtonSet from '../modal-set-button/modal-set-button';
import FormSelect from '../../components/form-select/form-select-simplified';
import { appColors } from '../../emotion/utils';

import { useQuery } from '../../hooks';

import Client, { ENDPOINT } from '../../client';
import FormCheckBox from '../form-check-box/form-check-box';
import * as Yup from 'yup';
import { debounce } from 'lodash';
import AuthContext from '../../context/auth-context';

export default function EditUserModal({
  setShowModal = () => {},
  open,
  onSuccess = () => {},
  user
}) {
  const handleCancel = () => {
    setShowModal(false)
  }

  const { runProtected } = useContext(AuthContext);

  const duplicateTest = (field) => debounce(async (value) => {
    try {
      const res = await runProtected(Client.get, `/users?${field}=${value}`);
      let canUse = true;
      res?.data?.forEach(element => {
        if(element.id !== user.id)canUse = false
      });
      return canUse;
    } catch (err) {
      console.error(err);
      return true;
    }
  }
, 250);
const editUserSchema = Yup.object({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required')
    .test(
      'duplicate-email',
      () => 'Email is already taken',
      duplicateTest('email')
    ),
  //phone: Yup.string().required('Phone is required'),
  //role: Yup.string().required('Role is required'),
  //assign_horse: Yup.array().min(1, 'At least one horse must be assigned'),
});

  const { mutateAsync: submitRequest } = useMutation({
    mutationKey: 'add-user',
    networkMode: 'always',
    mutationFn: async data => {
      console.log(data)
      data.is_admin = data.admin == true || data.admin == 1 ? true : false;
      data.is_barn_manager = data.manager == true || data.manager == 1 ? true : false;
      data.is_vet = data.vet == true || data.vet == 1? true : false;
      delete data.admin
      delete data.manager
      delete data.vet
      if(user) {
        Client.put(`/users/${user.id}`, data)
      } else {
        Client.post(`/users`, data)
      }
    },
    onSuccess: values => {
      console.log("On success")
      onSuccess()
      setShowModal(false)
    },
  });

  const horsesUri = useMemo(() => {
    const url = new URL("/horses?orderBy=name", ENDPOINT);
    return url.toString().replace(ENDPOINT, "");
  }, []);

  // get horses
  const { data: horsesData, refetch: refetchHorses } = useQuery(
    horsesUri
  );

  const initialValues = {
    first_name: user?.first_name,
    last_name: user?.last_name,
    email: user?.email,
    phone: user?.phone,
    assign_horse: user?.horses_owned.map(h => h.id),
    manager: user?.is_barn_manager ? 1 : 0,
    vet: user?.is_vet ? 1 : 0,
    admin: user?.is_admin ? 1 : 0,
  };

  console.log(initialValues)
  console.log("user", user)
  const horses = horsesData?.data.map((h) => ({
    label: h.name,
    value: h.id
  }));
  
  return (
    <>
      {/* <Modal title="Edit a User" open={isModalOpen} footer={null}> */}
      <Dialog
        header={
          <span css={css.dialogHeader} className="dialog-header">
            {user? "Edit a User" : "Add a User"}
          </span>
        }
        visible={true}
        style={{ width: 400 }}
        onHide={() => setShowModal(false)}
      >
        <Formik
            initialValues={initialValues}
            onSubmit={submitRequest}
            validationSchema={editUserSchema}
            enabledReinitialize
            validateOnBlur
            validateOnChange
          >
            {(formik) => (
              <Form>
                <label style={{fontWeight:"bold"}}>Special Permissions:<br/></label>
                {["Admin", "Manager", "Vet"].map((role) => {
                    return (
                      <FormCheckBox
                        key={role.toLowerCase()}
                        name={role.toLowerCase()}
                        label={role}
                        formik={formik}
                      />
                    );
                  })}
                <FormTextInput
                  formik={formik}
                  name="email"
                  label="Email"
                  labelPosition="top"
                  //readOnly
                />
                {formik.touched.email && formik.errors.email && <div id="feedback">{formik.errors.email}</div>}

                <FormTextInput
                  formik={formik}
                  name="first_name"
                  label="First Name"
                  labelPosition="top"
                />
                {formik.touched.first_name && formik.errors.first_name && <div id="feedback">{formik.errors.first_name}</div>}

                <FormTextInput
                  formik={formik}
                  name="last_name"
                  label="Last Name"
                  labelPosition="top"
                />
                {formik.touched.last_name && formik.errors.last_name && <div id="feedback">{formik.errors.last_name}</div>}

                <FormTextInput
                  formik={formik}
                  name="phone"
                  label="Phone"
                  labelPosition="top"
                />
                {formik.touched.phone && formik.errors.phone && <div id="feedback">{formik.errors.phone}</div>}

                <FormSelect
                  formik={formik}
                  name="assign_horse"
                  label="Assign Horse(s)"
                  placeholder="Select All That Apply"
                  options={horses}
                  isMulti={true}
                  onBlur={formik.handleBlur}
                />

                <ModalButtonSet
                  onCancel={handleCancel}
                  // isSubmitting={formik.isSubmitting}
                  onSubmit={formik.handleSubmit}
                  disabled={!formik.dirty}
                  submitButtonText="Save changes"
                  submitButtonColor={appColors?.greens?.base}
                />
              </Form>
            )}
        </Formik>
      </Dialog>
    </>
  );
}