/** @jsxImportSource @emotion/react */

import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { loadPlayer } from 'rtsp-relay/browser';

import defaultImage from "../../assets/loader.gif"
import Client from '../../client';

import { css } from './css';

const VideoPlayer = ({
    url, 
    setStatus,
    style,
    fullScreen = false
 }) => {
  const [loading, setLoading] = useState(true);

  const ref = useRef();
  const refDiv = useRef();

  useEffect(() => {
    if(fullScreen && ref?.current) {
      if (ref.current.requestFullscreen) {
        ref.current.requestFullscreen();
      } else if (ref.current.mozRequestFullScreen) {
        ref.current.mozRequestFullScreen();
      } else if (ref.current.webkitRequestFullscreen) {
        ref.current.webkitRequestFullscreen();
      } else if (ref.current.msRequestFullscreen) { 
        ref.current.msRequestFullscreen();
      }
    }
  }, [fullScreen])

  useEffect(() => {
    let player = null;
    let timeout = null;

    async function initVideo() {
      try {
        timeout = setTimeout(() => {
          setStatus("Camera connection failed, retrying...");
        }, 30 * 1000);

        player = await loadPlayer({
          url: `${url}?token=${window.localStorage.getItem("horsewise_refresh")}`,
          canvas: ref.current,
          onPlay: () => {
            setLoading(true)
            setStatus("Camera found, initializing stream")
          },
          onVideoDecode:(it) => {
            setLoading(false)
            if(ref && ref.current){ref.current.style.display = "block"; ref.current.style.width = "100%"}
            setStatus("Camera found, streaming...")
            clearTimeout(timeout);
          },
        });
      } catch (err) {
        setStatus("Camera connection failed");
        console.error(err)
      }
    }

    initVideo();

    return () => {
      if (player) player.destroy();
      clearTimeout(timeout);
    };
  }, [url, setStatus])

  const loader = (
    <div css={css.loader}>
      <img
        src={defaultImage}
        alt="loading"
        css={css.loader.img}
      />
    </div>
  );

  return (
    <div css={css.container(false, loading)} style={style}>
      
        {loading && loader}
        <canvas ref={ref} ></canvas>
      
    </div>
  );
};

VideoPlayer.props = {
  url: PropTypes.string.isRequired
};

export default VideoPlayer;