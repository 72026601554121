/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import { useState } from "react";

import Menu from "../menu/menu";

import { css } from "./css";
import Icon from "../icon/icon";

export default function Header({
  activePage,
  setActivePage,
  hideMenu = false,
}) {
  const [navOpen, setNavOpen] = useState(false);

  const logo = (
    <Link css={css.logo} to="/" className="logo">
      {/* <NavLogo /> */}
      <Icon icon="NavLogo" />
    </Link>
  );

  const menu = (
    <Menu
      activePage={activePage}
      setActivePage={setActivePage}
      navOpen={navOpen}
      setNavOpen={setNavOpen}
      hideMenu={hideMenu}
    />
  );

  return (
    <header css={css.header}>
      {logo}
      {menu}
    </header>
  );
}
