import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";

import AuthContext from "../context/auth-context";

const HOME_PATH = "/stalls";

function UnProtectedLayout(props) {
  const { userData } = useContext(AuthContext);

  console.log(userData)

  if (userData && userData.id) {
    return <Navigate to={HOME_PATH} replace></Navigate>;
  }

  return <Outlet></Outlet>;
}

export default UnProtectedLayout;
