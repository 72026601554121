/** @jsxImportSource @emotion/react */
import { useState, useEffect, useRef } from "react";
import { useQuery } from "../../hooks";
import StallSection from "./stall-section";
import StallTray from "./stall-tray";
import { css } from './css'

function Stalls() {
  const [activeStall, setActiveStall] = useState(null);

  const { data, refetch } = useQuery(
    `/stalls`,
  );

  
  useEffect(() => {
    //Refresh the activeStall if we got the stall data again
    if(data && activeStall) {
      const newActive = data.data.find((s) => s.id === activeStall.id)
      if(newActive)setActiveStall(newActive)
    }
  }, [data])
  


  return (
    <div className="container">
      <StallSection
        data={data?.data}
        setActiveStall={setActiveStall}
        activeStall={activeStall}
        className={activeStall ? 'tray-open' : ''}
      />

      {activeStall && (
        <div css={css.tray} className={activeStall ? 'open' : null}>
          <StallTray
            activeStall={activeStall}
            setActiveStall={setActiveStall}
            refetchStall={refetch}
          />
        </div>
      )}
    </div>
  )
}

export default Stalls
