import {
    appColors,
    dimensions,
    flexbox,
    paddingY,
    posFixed,
  } from "../../emotion/utils";
  
  export const css = {
    header: {
      ...flexbox("row", "center", "space-between", 0),
      ...paddingY(8),
      // ...posSticky(0, 0, 0, 0, 100),
      ...posFixed(0, 0, 0, 0, 100),
      backgroundColor: appColors?.greens?.base,
      height: 60,
      paddingRight: 8,
      paddingLeft: 24,

      "@media screen and (min-width: 600px)": {
        paddingLeft: 24,
        paddingRight: 30
      }
    },

    logo: {
      "> svg": {
        ...dimensions(145, "auto"),
        display: "block",

        "@media screen and (min-width: 600px)": {
          ...dimensions(166, 60)
        },
      },
    },
  };
  